<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Advertising from '@/services/Advertising'
import modalAddSponsor from "@/components/modals/advertising/modalAddSponsor";

/**
 * Sponsors component
 */
export default {
    components: { Layout, PageHeader, modalAddSponsor },
    page: {
        title: "Sponsor Wall",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    data() {
        return {
            title: "Sponsors",
            items: [
                {
                    text: "Advertising",
                },
                {
                    text: "Sponsors",
                    active: true,
                },
            ],
            sponsors: null,
            error: null,
            showLoader:false
        };
    },
    middleware: "authentication",

    async created(){
        this.getSponsors()
    },

    methods: {
        getSponsors(){
            this.showLoader = true;
            Advertising.getSponsors()
            .then(response => {
                this.sponsors =  response.data.data;
            })
            .catch(error => {
                this.error = error.response.data.error ? error.response.data.error : "";
            })
            .finally(() => {
                this.showLoader = false;
            })
        },

        modalAddNewSponsor() {
            this.$bvModal.show("add_new_sponsor");
        },
    },
};
</script>


<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

      <div class="row mb-3">
          <div class="col-md-6">
              <div class="mb-3">
                  <a  href="javascript:void(0);" class="btn btn-success waves-effect waves-light" @click="modalAddNewSponsor">
                      <i class="mdi mdi-plus me-2"></i>
                      Add New
                  </a>
              </div>
          </div>
      </div>

      <div class="row" v-if="!showLoader">
          <div class="col-lg-6 col-xl-3" v-for="sponsor in sponsors" :key="sponsor.id">
              <b-card no-body>
                  <b-card-body>
                      <b-card-title class="text-center">
                          <h4 class="card-title"><strong>{{sponsor.name}}</strong></h4>
                          <h6 class="card-subtitle text-muted">
                              Status:
                              <span v-html="format_status(sponsor.active ? 'active' : 'inactive', 'font-size-12')"></span>
                          </h6>
                      </b-card-title>

                      <div class="sponsor-logo-grid text-center">
                          <div>
                              <img class="img-fluid img-thumbnail" :src="sponsor.logo" />
                          </div>
                      </div>
                  </b-card-body>

                  <b-card-body class="text-center">
                      <router-link class="btn btn-primary" :to="{path: `/advertising/sponsor/${sponsor.id}`}">
                          View Details
                      </router-link>
                  </b-card-body>
              </b-card>
          </div>
      </div>
      <div class="row" v-else>
          <div class="card h-100">
              <div class="card-body d-flex align-items-center justify-content-center" >
                  <b-spinner large></b-spinner>
              </div>
          </div>
      </div>


      <!-- MODALS -->
      <modalAddSponsor @refreshScreen="getSponsors"></modalAddSponsor>
      <!-- END  MODALS -->
  </Layout>
</template>


